@import "bootstrap/dist/css/bootstrap.css";

.App {
  background-color: #282c34;
  min-height: 100vh;
}




